import axios from '../../helpers/api.service';

const state = {
    all: {},
    dataload: {},
    status: {}
};

const actions = {
    async getDataLoads({commit}) {
        const response = await axios().get('/dataload/');
        commit('SET_DATA_LOADS', response.data);
    },
    async getDataLoad({commit}, id) {
        const response = await axios().get('/dataload/' + id);
        commit('SET_DATA_LOAD', response.data);
    },
    async deleteDataLoad({dispatch}, id) {
        await axios().delete('/dataload/' + id);
        dispatch('getDataLoads');
    }
}

const mutations = {
    SET_DATA_LOADS(state, data) {
        state.all = {items: data};
    },
    SET_DATA_LOAD(state, data) {
        state.dataload = data;
    },
    SET_DATA_LOAD_STATUS(state, data) {
        state.status = data;
    }
};

export const dataloads = {
    namespaced: true,
    state,
    actions,
    mutations
};
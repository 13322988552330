import axios from '../../helpers/api.service';

const state = {
    all: {},
    production: {},
};

const actions = {
    async getProductions({commit}) {
        const response = await axios().get('/production');
        commit('SET_PRODUCTIONS', response.data);
    },
    async createProduction({dispatch}, data) {
        await axios().post('/production', data);
        dispatch('getProductions');
    },
    async editProduction({dispatch}, data) {
        await axios().put('/production', data);
        dispatch('getProductions');
    },
    async getProduction({commit}, id) {
        const response = await axios().get('/production/' + id);
        commit('SET_PRODUCTION', response.data);
    },
    async deleteProduction({dispatch}, id) {
        await axios().delete('/production/' + id);
        dispatch('getProductions');
    }
}


const mutations = {
    SET_PRODUCTIONS(state, productions) {
        state.all = {items: productions};
    },
    SET_PRODUCTION(state, data) {
        state.production = data;
    }
};

export const productions = {
    namespaced: true,
    state,
    actions,
    mutations
};
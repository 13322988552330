<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'app',
    metaInfo: {
        title: 'Altumatim',
        titleTemplate: '%s '
    },
    updated() {
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    }
}
</script>

import axios from '../../helpers/api.service';

const state = {
    documentCount: 0,
    eventCount: 0,
    topicCount: 0,
    entityCount: 0,
    briefcaseCount: 0
};

const actions = {
    async getDocumentCount({commit}, id) {
        const response = await axios().get('/dashboard/document-count/' + id);
        commit('SET_DOCUMENT_COUNT', response.data);
    },
    async getEventCount({commit}, id) {
        const response = await axios().get('/dashboard/event-count/' + id);
        commit('SET_EVENT_COUNT', response.data);
    },
    async getTopicCount({commit}, id) {
        const response = await axios().get('/dashboard/topic-count/' + id);
        commit('SET_TOPIC_COUNT', response.data);
    },
    async getEntityCount({commit}, id) {
        const response = await axios().get('/dashboard/entity-count/' + id);
        commit('SET_ENTITY_COUNT', response.data);
    },
    async getBriefcaseCount({commit}, data) {
        const response = await axios().get('/dashboard/briefcase-count/' + data.caseId + '/' + data.userId);
        commit('SET_BRIEFCASE_COUNT', response.data);
    }
};

const mutations = {
    SET_DOCUMENT_COUNT(state, data) {
        state.documentCount = data;
    },
    SET_EVENT_COUNT(state, data) {
        state.eventCount = data;
    },
    SET_TOPIC_COUNT(state, data) {
        state.topicCount = data;
    },
    SET_ENTITY_COUNT(state, data) {
        state.entityCount = data;
    },
    SET_BRIEFCASE_COUNT(state, data) {
        state.briefcaseCount = data;
    }
}

export const dashboard = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    all: {},
    briefcase: {},
    documentBriefcases: {},
    documents: {},
    filterBucket: {},
    documentCount: Number,

};

const actions = {
    async getAllBriefcases({commit}, data) {
        const response = await axios().get('/briefcases/' + data.caseId + '/' + data.userId);
        commit('SET_ALL_BRIEFCASES', response.data);
    },
    async getDocumentBriefcases({commit}, id) {
        const response = await axios().get('/briefcases/document/briefcases/' + id);
        commit('SET_DOCUMENT_BRIEFCASES', response.data);
    },
    async createBriefcase({dispatch}, data) {
        await axios().post('/briefcases/briefcase', data);
        dispatch('getAllBriefcases', data);
    },
    async editBriefcase({dispatch}, data) {
        await axios().put('/briefcases/briefcase', data);
        dispatch('getAllBriefcases', data);
    },
    async deleteBriefcase({dispatch}, data) {
        await axios().delete('/briefcases/briefcase/' + data.briefcaseId);
        dispatch('getAllBriefcases', data);
    },
    async getAllDocuments({commit}, data) {
        const response = await axios().post('/briefcases/document', data);
        commit('SET_ALL_DOCUMENTS', response.data);
    },
    async getDocuments({commit}, data) {
        const response = await axios().post('/briefcases/briefcase/documents', data);
        commit('SET_DOCUMENTS', response.data);
    },
    async postBriefcaseDocuments(_, data) {
        await axios().post('/briefcases/briefcase/document/', data);
    },
    async deleteBriefcaseDocument({dispatch}, id) {
        await axios().delete('/briefcases/briefcase/document/' + id);
        dispatch('getAllBriefcases');
    }
};

const mutations = {
    SET_ALL_BRIEFCASES(state, briefcases) {
        state.all = briefcases;
    },
    SET_DOCUMENT_BRIEFCASES(state, data) {
        state.documentBriefcases = data.briefcaseIds;
    },
    SET_ALL_DOCUMENTS(state, data) {
        state.documents = data.documents;
        state.documentCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
    SET_DOCUMENTS(state, data) {
        state.documents = data.documents;
        state.documentCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
};

export const briefcases = {
    namespaced: true,
    state,
    actions,
    mutations
};
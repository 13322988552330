import axios from '../../helpers/api.service';

const state = {
    documents: {},
    resultCount: Number,
    filterBucket: {},
    significantTerms: {},
};

const actions = {
    async getSearch({commit}, data) {
        const response = await axios().post('/search/query', data);
        commit('SET_SEARCH', response.data);
    },
    async getSignificant({commit}, query) {
        const response = await axios().post('/search/significant', query);
        commit('SET_SIGNIFICANT', response.data);
    },
    async getBrowse({commit}, query) {
        const response = await axios().post('/search/browse', query);
        commit('SET_BROWSE', response.data);
    },
}

const mutations = {
    SET_SEARCH(state, data) {
        state.documents = data.documents;
        state.resultCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
    SET_BROWSE(state, data) {
        state.documents = data.documents;
        state.resultCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
    SET_SIGNIFICANT(state, data) {
        state.significantTerms = data.significantTerms;
    }
};

export const search = {
    namespaced: true,
    state,
    actions,
    mutations
};
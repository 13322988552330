import axios from '../../helpers/api.service';

const state = {
    all: {},
    activity: {},
    caseId: Number
};

const actions = {
    async getTasks({commit}, caseId) {
        const response = await axios().get('/tasks/' + caseId);
        commit('SET_TASKS', response.data);
    },
    async deleteTask({commit}, id) {
        const res = await axios().delete('/tasks/task/' + id);
        const response = await axios().get('/tasks/' + res.data);
        commit('SET_TASKS', response.data);
    },
    async createTask({commit}, data) {
        await axios().post('/tasks', data);
        const response = await axios().get('/tasks/' + data.caseId);
        commit('SET_TASKS', response.data);
    },
    async editTask({commit}, data) {
        await axios().post('/tasks/task', data);
        const response = await axios().get('/tasks/' + data.caseId);
        commit('SET_TASKS', response.data);
    },
    async createActivity({dispatch}, data) {
        await axios().post('/tasks/task/activity', data);
        dispatch('getActivity', data.taskId);
    },
    async editActivity({dispatch}, data) {
        await axios().put('/tasks/task/activity', data);
        dispatch('getActivity', data.taskId);
    },
    async getActivity({commit}, taskId) {
        const response = await axios().get('/tasks/task/activity/' + taskId);
        commit('SET_ACTIVITY', response.data);
    },
    async deleteActivity({commit}, id) {
        const res = await axios().delete('/tasks/task/activity/' + id);
        const response = await axios().get('/tasks/task/activity/' + res.data);
        commit('SET_ACTIVITY', response.data);
    },
    async setTaskStatus({dispatch}, data) {
        await axios().post('/tasks/task', data);
        dispatch("getTasks", data.caseId);
    }
};

const mutations = {
    SET_TASKS(state, tasks) {
        state.all = {items: tasks};
    },
    SET_ACTIVITY(state, activities) {
        state.activity = {items: activities};
    }
};

export const tasks = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    storylineDocuments: {},
    computedDocuments: {},
    eventDocuments: {},

    storylineCount: Number,
    computedCount: Number,
    eventCount: Number,
    filterBucket: {},
    eventFilterBucket: {},
    computedFilterBucket: {},

    selectedEvents: []
};

const actions = {
    async getStorylineDocuments({commit}, data) {
        const response = await axios().post('/storyline/documents', data);
        commit('SET_STORYLINE', response.data);
    },
    async getComputedDocuments({commit}, data) {
        const response = await axios().post('/storyline/computed', data);
        commit('SET_COMPUTED', response.data);
    },
    async getComputedDocumentsCount({commit}, data) {
        const response = await axios().post('/storyline/computed-count', data);
        commit('SET_COMPUTED_COUNT', response.data);
    },
    async getEventDocuments({commit}, data) {
        const response = await axios().post('/storyline/event/documents', data);
        commit('SET_EVENT', response.data);
    },
    async runAnalysis(_, data) {
        await axios().post('/storyline/analysis', data);
    },
    async updateSelectedEvents({commit}, data) {
        commit('SET_SELECTED_EVENTS', data);
    }
}

const mutations = {
    SET_STORYLINE(state, data) {
        state.storylineDocuments = data.documents;
        state.storylineCount = data.totalHits;
        state.filterBucket = data.bucket;
    },
    SET_COMPUTED(state, data) {
        state.computedDocuments = data.documents;
        state.computedCount = data.totalHits;
        state.computedFilterBucket = data.bucket;
    },
    SET_COMPUTED_COUNT(state, data) {
        state.computedCount = data;
    },
    SET_EVENT(state, data) {
        state.eventDocuments = data.documents;
        state.eventCount = data.totalHits;
        state.eventFilterBucket = data.bucket;
    },
    SET_SELECTED_EVENTS(state, data) {
        state.selectedEvents = data;
    }
};

export const storyline = {
    namespaced: true,
    state,
    actions,
    mutations
};
const state = {
    breadcrumb: 'Dashboard',
};


const actions = {
    setBreadCrumb({commit}, value) {
        commit('SET_BREADCRUMB', value);
    },
};

const mutations = {
    SET_BREADCRUMB(state, value) {
        state.breadcrumb = value;
    }
};

export const navigation = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    nodes: [],
    edges: [],
    entities: null,
    topics: null,
    events: null,
    isComputedQuery: false,
    nodeData: {
        associatedCount: 0,
        mentionedCount: 0,
        authoredCount: 0
    },
    edgeData: {
        totalHits: 0
    }
};

const actions = {
    async setEntities({commit}, data) {
        commit('SET_ENTITIES', data);
    },
    async setTopics({commit}, data) {
        commit('SET_TOPICS', data);
    },
    async setEvents({commit}, data) {
        commit('SET_EVENTS', data);
    },
    async getConnections({commit}, id) {
        const data = {entities: state.entities, events: state.events, topics: state.topics, caseId: id};
        let response, response2;
        if (state.isComputedQuery) {
            response = await axios().post('/network/computed/nodes', data);
            response2 = await axios().post('/network/computed/edges', data);
        } else {
            response = await axios().post('/network/nodes', data);
            response2 = await axios().post('/network/edges', data);
        }

        commit('SET_NODES', response.data);
        commit('SET_EDGES', response2.data);
    },
    async getNodeData({commit}, data) {
        const response = await axios().post('/network/node/documents', data);
        commit('SET_NODE_DATA', response.data);
    },
    async getNodeComputedData({commit}, data) {
        const response = await axios().post('/network/node/computed/documents', data);
        commit('SET_NODE_DATA', response.data);
    },
    async getEdgesData({commit}, data) {
        const response = await axios().post('/network/edges/documents', data);
        commit('SET_EDGE_DATA', response.data);
    },
    async getEdgesComputedData({commit}, data) {
        const response = await axios().post('/network/edges/computed/documents', data);
        commit('SET_EDGE_DATA', response.data);
    },
    setComputedQuery({commit}, data) {
        commit('SET_COMPUTED_QUERY', data);
    }
};

const mutations = {
    SET_NODES(state, data) {
        state.nodes = data;
    },
    SET_EDGES(state, data) {
        state.edges = data;
    },
    SET_ENTITIES(state, data) {
        state.entities = data;
    },
    SET_TOPICS(state, data) {
        state.topics = data;
    },
    SET_EVENTS(state, data) {
        state.events = data;
    },
    SET_NODE_DATA(state, data) {
        state.nodeData = data;
    },
    SET_EDGE_DATA(state, data) {
        state.edgeData = data;
    },
    SET_COMPUTED_QUERY(state, data) {
        state.isComputedQuery = data;
    }
};

const getters = {
    getEdges(state) {
        return state.edges;
    },
    getNodes() {
        return state.nodes;
    }
};

export const network = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
<template>
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
        <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
            <div class="pt-3">
                <span class="footer-text font-weight-bolder">Altumatim</span> © {{ currentYear }}
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'app-layout-footer',

    methods: {
        getLayoutFooterBg() {
            return `bg-${this.layoutFooterBg}`
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear()
        }
    }
}
</script>
import axios from '../../helpers/api.service';

const state = {
    all: {},
    view: {},
    teamForm: {}
};

const actions = {

    async getAllTeams({commit}) {
        const response = await axios().get('/teams');
        commit('SET_ALL_TEAMS', response.data);
    },
    async getTeamView({commit}, id) {
        const response = await axios().get('/teams/view/' + id);
        commit('SET_TEAM_VIEW', response.data);
    },
    async deleteTeam({dispatch}, data) {
        await axios().delete('/teams/team/' + data.userId + '/' + data.caseId)
        dispatch('getTeamView', data.caseId);
        dispatch('getAllTeams');
    },
    async createTeam({commit, dispatch}, data) {
        await axios().post('/teams', data);
        const response = await axios().get('/teams/view/' + data.caseId);
        commit('SET_TEAM_VIEW', response.data);
        dispatch('getAllTeams');
    },
    async editTeam(data) {
        await axios().put('/teams', data);
    }
};

const mutations = {

    SET_ALL_TEAMS(state, teams) {
        state.all = {items: teams};
    },
    SET_TEAM_VIEW(state, teamView) {
        state.view = {items: teamView};
    },
};

export const teams = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    all: {},
    client: {}
};

const actions = {
    async loadClients({commit}) {
        const response = await axios().get('/clients');
        commit('SET_CLIENTS', response.data);
    },
    async getCaseClient({commit}, caseId) {
        const response = await axios().get('/clients/case/' + caseId);
        commit('SET_CLIENT', response.data);
    },
    async deleteClient({dispatch}, id) {
        await axios().delete('/clients/client/' + id)
        dispatch('loadClients');
    },
    async createClient({dispatch}, data) {
        await axios().post('/clients', data);
        dispatch('loadClients');
    },
    async editClient({dispatch}, data) {
        await axios().put('/clients', data);
        dispatch('loadClients');
    }
};

const mutations = {
    SET_CLIENTS(state, clients) {
        state.all = {items: clients};
    },
    SET_CLIENT(state, client) {
        state.client = client;
    },
};

export const clients = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';
import {authHeader} from './auth-header';

class AuthService {
    login(user) {
        return axios().post('/authenticate/login', {
            username: user.username,
            password: user.password
        })
            .then(response => {
                if (response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    validate() {
        return axios().post('/authenticate/validate', null, {headers: authHeader()})
            .then(response => {
                if (!response.data) {
                    location.href = "/login"
                }
            });
    }

    register(user) {
        return axios().post('/authenticate/signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();
import axios from '../../helpers/api.service';

const state = {
    facebookData: {},
    postTotal: {},
    dmTotal: {},
    followersTotal: {},
    fbFriends: {},
    fbDirectMessages: {}
};

const actions = {
    // STATS ---------------------------------------------------------------------------------------------------------------------------------------//

    async getPostsChart({commit}, entityId) {
        const response = await axios().get('/socialmedia/stats/postschart/' + entityId);
        commit('SET_FACEBOOK_DATA', response.data);
    },
    async getPostsTotal({commit}, entityId) {
        const response = await axios().get('/socialmedia/stats/poststotal/' + entityId);
        commit('SET_POST_TOTAL', response.data);
    },
    async getDMTotal({commit}, entityId) {
        const response = await axios().get('/socialmedia/stats/dmtotal/' + entityId);
        commit('SET_DM_TOTAL', response.data);
    },
    async getFollowersTotal({commit}, entityId) {
        const response = await axios().get('/socialmedia/stats/followersTotal/' + entityId);
        commit('SET_FOLLOWERS_TOTAL', response.data);
    },

    // FACEBOOK ------------------------------------------------------------------------------------------------------------------------------------//

    async getFacebookData({commit}, data) {
        const response = await axios().get('/socialmedia/facebook/' + data.entityId + "/" + data.category);
        commit('SET_FACEBOOK_DATA', response.data);
    },
    async getFBFriends({commit}, entityId) {
        const response = await axios().get('/facebook/friends/' + entityId);
        commit('SET_FB_FRIENDS', response.data);
    },
    async getFBDirectMessages({commit}, entityId) {
        const response = await axios().get('/facebook/dm/' + entityId);
        commit('SET_FB_DM', response.data);
    },
};

const mutations = {
    SET_FACEBOOK_DATA(state, data) {
        state.facebookData = data;
    },
    SET_POST_TOTAL(state, data) {
        state.postTotal = data;
    },
    SET_DM_TOTAL(state, data) {
        state.dmTotal = data;
    },
    SET_FOLLOWERS_TOTAL(state, data) {
        state.followersTotal = data;
    },
    SET_FB_FRIENDS(state, data) {
        state.fbFriends = data;
    },
    SET_FB_DM(state, data) {
        state.fbDirectMessages = data;
    },
};

export const socialmedia = {
    namespaced: true,
    state,
    actions,
    mutations,
};
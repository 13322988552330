import Vue from 'vue'
import App from './App'
import router from './helpers/router'

import BootstrapVue from 'bootstrap-vue'
import globals from './helpers/globals'
import {store} from './service';
import Popper from 'popper.js'
import VueSlideoutPanel from 'vue2-slideout-panel';
import Vuelidate from 'vuelidate'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-animate/dist/vue2-animate.min.css'

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueSlideoutPanel)
Vue.use(Vuelidate)

Vue.mixin({
    data: globals
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

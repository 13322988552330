import axios from 'axios'
import {baseURL} from '@/helpers/app.config';

export default () => {
    return axios.create({
        baseURL: baseURL + '/rest',
        withCredentials: false,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
}

const user = JSON.parse(localStorage.getItem('user'));
if (user) {
    axios.defaults.headers.common["Authorization"] = user.jwt;
}


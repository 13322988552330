import axios from '../../helpers/api.service';

const state = {
    all: {},
    profile: {},
    caseNonUsers: {},
};

const actions = {
    async loadUsers({commit}) {
        const response = await axios().get('/users');
        commit('SET_USERS', response.data);
    },
    async loadCaseUsers({commit}, id) {
        const response = await axios().get('/users/' + id);
        commit('SET_USERS', response.data);
    },
    async deleteUser({dispatch}, id) {
        await axios().delete('/users/user/' + id)
        dispatch('loadUsers');
    },
    async createUser({dispatch}, data) {
        await axios().post('/users', data);
        dispatch('loadUsers');
    },
    async getUser({commit}, id) {
        const response = await axios().get('/users/user/' + id);
        commit('SET_PROFILE', response.data);
    },
    async editUser({dispatch}, data) {
        await axios().put('/users', data);
        dispatch('loadUsers');
    },
    async getCaseNotUser({commit}, id) {
        const response = await axios().get('/users/case-non-users/' + id);
        commit('SET_CASE_NON_USERS', response.data);
    },
};

const mutations = {
    SET_USERS(state, users) {
        state.all = {items: users};
    },
    SET_PROFILE(state, profile) {
        state.profile = profile;
    },
    SET_CASE_NON_USERS(state, users) {
        state.caseNonUsers = users;
    },
};

export const users = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    all: {},
    review: {}
};

const actions = {
    async getAllReviews({commit}) {
        const response = await axios().get('/review');
        commit('SET_REVIEWS', response.data);
    },
    async getReviews({commit}, id) {
        const response = await axios().get('/review/case/' + id);
        commit('SET_REVIEWS', response.data);
    },
    async createReview({dispatch}, data) {
        await axios().post('/review', data);
        dispatch('getAllReviews');
    },
    async editReview({dispatch}, data) {
        await axios().put('/review', data);
        dispatch('getReviews', data.reviewId);
    },
    async getReview({commit}, id) {
        const response = await axios().get('/review/' + id);
        commit('SET_REVIEW', response.data);
    },
    async updateStatus({dispatch}, data) {
        await axios().get('/review/update-status/' + data.id + '/' + data.status + '/' + data.progress);
        dispatch('getAllReviews');
    },
    async deleteReview({dispatch}, data) {
        await axios().delete('/review/' + data.id);
        dispatch('getAllReviews');
    }
}


const mutations = {
    SET_REVIEWS(state, data) {
        state.all = {items: data};
    },
    SET_REVIEW(state, data) {
        state.review = data;
    }
};

export const reviews = {
    namespaced: true,
    state,
    actions,
    mutations
};
import axios from '../../helpers/api.service';

const state = {
    people: [],
    organizations: [],
    all: [],
    entity: {},
    documentEntities: {}
};

const actions = {
    async getEntities({commit}, caseId) {
        const response = await axios().get('/entities/' + caseId);
        commit('SET_ALL', response.data);
    },
    async getEntity({commit}, id) {
        const response = await axios().get('/entities/entity/' + id);
        commit('SET_ENTITY', response.data);
    },
    async getPeople({commit}, caseId) {
        const response = await axios().get('/entities/people/' + caseId);
        commit('SET_PEOPLE', response.data);
    },
    async getOrganizations({commit}, caseId) {
        const response = await axios().get('/entities/organizations/' + caseId);
        commit('SET_ORGANIZATIONS', response.data);
    },
    async getDocumentEntities({commit}, id) {
        const response = await axios().get('/entities/document-entities/' + id);
        commit('SET_DOCUMENT_ENTITIES', response.data);
    },
    async postPeople({dispatch}, data) {
        await axios().post('/entities', data);
        dispatch('getPeople', data.caseId);
    },
    async postOrganizations({dispatch}, data) {
        await axios().post('/entities', data);
        dispatch('getOrganizations', data.caseId);
    },
    async postEntity({dispatch}, data) {
        await axios().post('/entities', data);
        dispatch('getEntities', data.caseId);
    },
    async postDocumentEntity(_, data) {
        await axios().post('/entities/document-entities', data);
    },
    async editEntity({dispatch}, data) {
        await axios().put('/entities', data);
        dispatch('getEntities', data.caseId);
    },
    async deleteEntity({dispatch}, data) {
        await axios().delete('/entities/entity/' + data.entityId);
        dispatch('getEntities', data.caseId);
    }
};

const mutations = {
    SET_ALL(state, data) {
        state.all = data;
    },
    SET_PEOPLE(state, data) {
        state.people = data;
    },
    SET_ORGANIZATION(state, data) {
        state.organizations = data;
    },
    SET_ENTITY(state, data) {
        state.entity = data;
    },
    SET_DOCUMENT_ENTITIES(state, data) {
        state.documentEntities = data;
    },
};

export const entities = {
    namespaced: true,
    state,
    actions,
    mutations,
};
<template>
    <div class="router-transitions">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'app-layout-blank'
}
</script>

import axios from '../../helpers/api.service';

const state = {
	reviewJob: {},
	reviewJobList: [],
	responsiveDocument: {},
	responsive: {},
	responsiveResult: [],
	responsiveTotalCount: 0,
	responsiveDocumentResultSetType: {},
	privilege: {},
	privilegeResult: [],
	privilegeTotalCount: 0,
	privilegeDocument: {},
	privilegeDocumentResultSetType: {},
	feedbackLoop: {},
	trialRuns: [],
	trialRun: {}
}

const actions = {
	// Review Job ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
	async createReviewJob({dispatch}, data) {
		await axios().post('/review-job', data);
		dispatch('getReviewJobList', data.reviewId);
	},
	async getReviewJob({commit}, id) {
		const response = await axios().get('/review-job/' + id);
		commit('SET_REVIEW_JOB', response.data);
	},
	async getReviewJobList({commit}, reviewId) {
		const response = await axios().get('/review-job/reviews/' + reviewId);
		commit('SET_REVIEW_JOB_LIST', response.data);
	},
	async deleteReviewJob({dispatch}, data) {
		await axios().delete('/review-job/' + data.id);
		dispatch('getReviewJobList', data.reviewId);
	},
	async updateReviewProtocol(_, data) {
		await axios().post('/review-job/update-review-protocol' , data);
	},
	// Responsive ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
	async getResponsive({commit}, id) {
		const response = await axios().get('/responsive/' + id);
		commit('SET_RESPONSIVE', response.data);
	},
	async getResponsiveResult({commit}, data) {
		const response = await axios().post('/responsive/responsive-documents', data);
		commit('SET_RESPONSIVE_RESULT', response.data);
	},
	async getResponsiveTotalCount({commit}, data) {
		const response = await axios().post('/responsive/count', data);
		commit('SET_RESPONSIVE_TOTAL_COUNT', response.data);
	},
	async getResponsiveDocument({commit}, id) {
		const response = await axios().get('/responsive/responsive-document/' + id);
		commit('SET_RESPONSIVE_DOCUMENT', response.data);
	},
	async updateResponsiveDocument(_, data) {
		await axios().post('/responsive/review-update', data);
	},
	async resetResponsiveDocument(_, data) {
		await axios().post('/responsive/reset', data);
	},
	async getResponsiveDocumentResultSetType({commit}, data) {
		const response = await axios().get('/responsive/responsive-document/result-set-type/' + data.reviewJobId + '/'+ data.documentId + '/' + data.resultSetType );
		commit('SET_RESPONSIVE_DOCUMENT_RESULT_SET_TYPE', response.data);
	},

	// Privilege -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
	async getPrivilege({commit}, id) {
		const response = await axios().get('/privilege/' + id);
		commit('SET_PRIVILEGE', response.data);
	},
	async getPrivilegeResult({commit}, data) {
		const response = await axios().post('/privilege/privilege-documents', data);

		commit('SET_PRIVILEGE_RESULT', response.data);
	},
	async getPrivilegeDocument({commit}, id) {
		const response = await axios().get('/privilege/privilege-document/' + id);
		commit('SET_PRIVILEGE_DOCUMENT', response.data);
	},
	async getPrivilegeDocumentResultSetType({commit}, data) {
		const response = await axios().get('/privilege/privilege-document/result-set-type/' + data.reviewJobId + '/'+ data.documentId + '/' + data.resultSetType );
		commit('SET_PRIVILEGE_DOCUMENT_RESULT_SET_TYPE', response.data);
	},
	async getPrivilegeTotalCount({commit}, data) {
		const response = await axios().post('/privilege/count', data);
		commit('SET_PRIVILEGE_TOTAL_COUNT', response.data);
	},
	async updatePrivilegeDocument(_, data) {
		await axios().post('/privilege/review-update', data);
	},
	async updatePrivilegeResult(_, data) {
		await axios().post('/privilege/result-update', data);
	},
	// Feedback Loop ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//
	async getFeedbackLoop({commit}, id) {
		console.log("feedback loop id", id)
		const response = await axios().get('/feedback-loop/'+ id);
		commit('SET_FEEDBACK_LOOP', response.data);
	},
	// Trial Runs ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------//

	async getTrialRuns({commit}, id) {
		const response = await axios().get('/feedback-loop/trial-runs/'+ id);
		commit('SET_TRIAL_RUNS', response.data);
	},
	async getTrialRun({commit}, id) {
		const response = await axios().post('/feedback-loop/trial-run', id);
		commit('SET_TRIAL_RUN', response.data);
	},
	async startTrialRun(_, id) {
		await axios().get('/feedback-loop/start-trial-run/' + id);
	}
}

const mutations = {

	SET_REVIEW_JOB(state, data) {
		state.reviewJob= data;
	},
	SET_REVIEW_JOB_LIST(state, data) {
		state.reviewJobList = data;
	},
	SET_RESPONSIVE(state, data) {
		state.responsive = data;
	},
	SET_RESPONSIVE_RESULT(state, data) {
		state.responsiveResult = data;
	},
	SET_RESPONSIVE_TOTAL_COUNT(state, data) {
		state.responsiveTotalCount = data;
	},
	SET_RESPONSIVE_DOCUMENT(state, data) {
		state.responsiveDocument = data;
	},
	SET_RESPONSIVE_DOCUMENT_RESULT_SET_TYPE(state, data) {
		state.responsiveDocumentResultSetType = data;
	},
	SET_PRIVILEGE(state, data) {
		state.privilege = data;
	},
	SET_PRIVILEGE_DOCUMENT(state, data) {
		state.privilegeDocument = data;
	},
	SET_PRIVILEGE_RESULT(state, data) {
		state.privilegeResult = data;
	},
	SET_PRIVILEGE_DOCUMENT_RESULT_SET_TYPE(state, data) {
		state.privilegeDocumentResultSetType = data;
	},
	SET_PRIVILEGE_TOTAL_COUNT(state, data) {
		state.privilegeTotalCount = data;
	},
	SET_FEEDBACK_LOOP(state, data) {
		state.feedbackLoop = data;
	},
	SET_TRIAL_RUNS(state, data) {
		state.trialRuns = data;
	},
	SET_TRIAL_RUN(state, data) {
		state.trialRun = data;
	},
};

export const autonomous = {
	namespaced: true,
	state,
	actions,
	mutations
};
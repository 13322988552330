import axios from '../../helpers/api.service';

const state = {
    all: {},
    caseId: Number,
    documentCount: 0,
    keyDocumentCount: 0,
    categories: [],
    category: "all"
};

const actions = {

    /* Event ---------------------------------------------------------------------------------------------------------------------------------------*/

    async getEvents({commit}, caseId) {
        const response = await axios().get('/events/' + caseId);
        commit('SET_EVENTS', response.data);
        commit('SET_CATEGORY', "all");
    },
    async deleteEvent({dispatch}, id) {
        const res = await axios().delete('/events/event/' + id);
        dispatch('getEvents', res.data);
    },
    async createEvent({dispatch}, data) {
        await axios().post('/events', data);
        dispatch('getEvents', data.caseId);
    },
    async editEvent({dispatch}, data) {
        await axios().put('/events', data);
        dispatch('getEvents', data.caseId);
    },

    /* Category ------------------------------------------------------------------------------------------------------------------------------------*/

    async postCategory({dispatch}, data) {
        await axios().post('/events/category', data);
        dispatch('getCategories', data.caseId);
    },
    async getCategories({commit}, caseId) {
        const response = await axios().get('/events/category/' + caseId);
        commit('SET_CATEGORIES', response.data);
    },
    async getCategoryEvent({commit}, data) {
        const response = await axios().get('/events/event/category/' + data.caseId + '/' + data.category);
        commit('SET_EVENTS', response.data);
        commit('SET_CATEGORY', data.category);
    },

    /* Event Document Count ------------------------------------------------------------------------------------------------------------------------*/

    async getEventDocumentCount({commit}, caseId) {
        const response = await axios().get('/events/event/documents/count/' + caseId);
        commit('SET_EVENT_DOC_COUNT', response.data);
    },
    async getEventKeyDocumentCount({commit}, caseId) {
        const response = await axios().get('/events/event/key-documents/count/' + caseId);
        commit('SET_EVENT_KEY_DOC_COUNT', response.data);
    },
};

const mutations = {
    SET_EVENTS(state, events) {
        state.all = {items: events};
    },
    SET_EVENT_DOC_COUNT(state, count) {
        state.documentCount = count;
    },
    SET_EVENT_KEY_DOC_COUNT(state, count) {
        state.keyDocumentCount = count;
    },
    SET_CATEGORIES(state, data) {
        state.categories = data;
    },
    SET_CATEGORY(state, data) {
        state.category = data;
    }
};

const getters = {
    getDocCount(state) {
        return state.documentCount;
    },
    getAllCategories() {
        return state.categories;
    }
};

export const events = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
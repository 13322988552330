<template>
    <div class="layout-wrapper layout-1">
        <div class="layout-inner">
            <app-layout-navbar/>

            <div class="layout-container">
                <div class="layout-content">

                    <router-view/>

                    <app-layout-footer/>
                </div>
            </div>
        </div>
        <div class="layout-overlay" @click="closeSidenav"></div>
    </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutFooter from './LayoutFooter'

export default {
    name: 'app-layout',
    components: {
        'app-layout-navbar': LayoutNavbar,
        'app-layout-footer': LayoutFooter
    },

    mounted() {
        this.layoutHelpers.init()
        this.layoutHelpers.update()
        this.layoutHelpers.setAutoUpdate(true)
        this.layoutHelpers.setNavbarFixed(true)
        this.layoutHelpers.setFooterFixed(true)
        this.layoutHelpers.setCollapsed(true, false)
    },

    beforeDestroy() {
        this.layoutHelpers.destroy()
    },

    methods: {
        closeSidenav() {
            this.layoutHelpers.setCollapsed(true)
        }
    }
}
</script>
<style>
.layout-container {
    padding-top: 52px !important;
}
</style>

import layoutHelpers from '@/helpers/layout.js'

export default function () {
    return {
        // Base url
        baseUrl: process.env.BASE_URL,

        // Layout helpers
        layoutHelpers,

        // Check for RTL layout
        get isRTL() {
            return document.documentElement.getAttribute('dir') === 'rtl' ||
                document.body.getAttribute('dir') === 'rtl'
        },

        // Check if IE
        get isIEMode() {
            return typeof document['documentMode'] === 'number'
        },

        // Check if IE10
        get isIE10Mode() {
            return this.isIEMode && document['documentMode'] === 10
        },

        // Layout navbar color
        get layoutNavbarBg() {
            return 'navbar-theme'
        },

        // Layout sidenav color
        get layoutSidenavBg() {
            return 'sidenav-theme'
        },

        // Layout footer color
        get layoutFooterBg() {
            return 'footer-theme'
        },

        // Animate scrollTop
        scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
            if (element.scrollTop === to) return
            const start = element.scrollTop
            const change = to - start
            const startDate = +new Date()

            // t = current time; b = start value; c = change in value; d = duration
            const easeInOutQuad = (t, b, c, d) => {
                t /= d / 2
                if (t < 1) return c / 2 * t * t + b
                t--
                return -c / 2 * (t * (t - 2) - 1) + b
            }

            const animateScroll = () => {
                const currentDate = +new Date()
                const currentTime = currentDate - startDate
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll)
                } else {
                    element.scrollTop = to
                }
            }

            animateScroll()
        },

        dateFormatter(date) {
            return new Date(date).toLocaleString()
        },

        dateFormatterShort(date) {
            return new Date(date).toDateString()
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            // return [year, month, day].join('-');

            return [month, day, year].join('/');
        },

        formatDateAlt(date) {
            if (date !== null) {
                let d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            } else {
                return null;
            }
        },

        text_truncate(str, length) {
            if (length == null) {
                length = 100;
            }

            let ending = ' ...';

            if (str.length > length) {
                str = str.substring(0, length - ending.length);
                var lastIndex = str.lastIndexOf(" ");
                return str.substring(0, lastIndex) + ending;
            } else {
                return str;
            }
        },

        truncateText(str, limit) {
            if (!str || !limit) return;
            let content = str.trim();
            content = content.split(' ').slice(0, limit);
            content = content.join(' ');

            if (str.length != content.length) {
                content += " ..."
            }
            return content;
        },

        truncateChar(str, limit) {
            if (!str || !limit) return;
            let content = str.trim();
            content = content.substring(0, limit)
            if (str.length != content.length) {
                content += " ..."
            }
            return content
        },

        text2HTML(textin) {
            let text = textin.replace(/&/g, "&amp;");
            text = text.replace(/\r\n?|\n/g, "<br>");
            text = text.replace(/<br>\s*<br>/g, "</p><p>");
            // text = text.replace(/<(?!\s*br\s*\/?)[^>]+>/gi, "");
            text = "<p>" + text + "</p>";


            return text;
        },

        cleanText2HTML(str) {
            if(str)
            {
                return str.replace(/\\n/g, "<br>");
            }
            else {
                return "";
            }

        },

        cleanHTML(textin) {
            if (textin) {
                return textin.replace(/<\/?[^>]+(>|$)/g, "");
            } else {
                return "";
            }
        },
        numberWithCommas(x) {
            if (x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return 0;
            }
        },

        capFirstLetter(text) {
            text = text.toLowerCase();
            return text.charAt(0).toUpperCase() + text.slice(1);
        },

        encodeURL(str) {
            str = str.replace(/[+]/g, "%2B");
            str = str.replace(/[-]/g, "%2D");
            str = str.replace(/[|]/g, "%7C");
            str = str.replace(/[(]/g, "%28");
            str = str.replace(/[)]/g, "%29");
            str = str.replace(/[*]/g, "%2A");
            return str;
        },
        refreshModule() {
            location.reload();
        },
    }
}

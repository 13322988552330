import axios from '../../helpers/api.service';

const state = {
    all: {},
    request: {},
    request_data: [],
    request_data_document: {},
    documentCount: 0,
    requestDocumentCount: 0,
    responsiveCount: 0
};

const actions = {
    async getRequests({commit}, id) {
        const response = await axios().get('/request/review/' + id);
        commit('SET_REQUESTS', response.data);
    },
    async runRequest( _ , id) {
        await axios().get('/request/run/' + id);
    },
    async getDocumentCount({commit}, caseId) {
        const response = await axios().get('/request/document-count/' + caseId);
        commit('SET_DOCUMENT_COUNT', response.data);
    },
    async createRequest({dispatch}, data) {
        await axios().post('/request', data);
        dispatch('getRequests', data.reviewId);
    },
    async editRequest({dispatch}, data) {
        await axios().put('/request', data);
        dispatch('getRequests', data.reviewId);
    },
    async deleteRequest({dispatch}, data) {
        await axios().delete('/request/' + data.id);
        dispatch('getRequests', data.reviewId);
    },
    async enableRequest({dispatch}, data) {
        await axios().get('/request/enabled/' + data.requestId + '/' + data.enabled);
        dispatch('getRequests', data.reviewId);
    },
    async getRequest({commit}, id) {
        const response = await axios().get('/request/' + id);
        commit('SET_REQUEST', response.data);
    },
    async getRequestData({commit}, id) {
        const response = await axios().get('/request/request-data/' + id);
        commit('SET_REQUEST_DATA', response.data);
    },
    async getRequestDataDocument({commit}, data) {
        const response = await axios().get('/request/request-data/' + data.requestId + '/' + data.documentId);
        commit('SET_REQUEST_DATA_DOCUMENT', response.data);
    }
}


const mutations = {
    SET_REQUESTS(state, data) {
        state.all = {items: data};
    },
    SET_REQUEST(state, data) {
        state.request = data;
    },
    SET_REQUEST_DATA(state, data) {
        state.request_data = data;
    },
    SET_DOCUMENT_COUNT(state, data) {
        state.documentCount = data;
    },
    SET_REQUEST_DOCUMENT_COUNT(state, data) {
        state.requestDocumentCount = data;
    },
    SET_RESPONSIVE_COUNT(state, data) {
        state.responsiveCount = data;
    },
    SET_REQUEST_DATA_DOCUMENT(state, data) {
        state.request_data_document = data;
    }
};

export const requests = {
    namespaced: true,
    state,
    actions,
    mutations
};